/* ===================================================================
  # Subscribe form
  ================================================================ */
.subscribe {
	padding: 2rem 0;
	&__text {
		margin: 0;
	}
}

.subscribe-form {
	$b: &;

	margin: 1.5rem 0 1rem;
	// background-color: $white;

	@include media($medium) {
		margin: 0;
	}

	input[type='text'],
	input[type='email'] {
		padding: 0.8rem 1rem;
		margin: 0;
		line-height: 1.5rem;
		color: $black;

		@include media($medium) {
			padding: 0.7rem 1.5rem;
		}
	}

	&__field {
		margin: 0;

		input[type='text'],
		input[type='email'] {
			border: none;

			&.focus label {
				transform: translateY(-25px);
			}
		}
	}

	&__left {
		@include media-max($medium) {
			margin-bottom: 8px;
		}
	}

	&__right {
		background-color: $white;
		border-top-right-radius: $button-border-radius;
		border-bottom-right-radius: $button-border-radius;

		@include media($medium) {
			margin-left: 5px;
		}
	}

	.form__field--text {
		label {
			// padding: 0.2rem 0;
			line-height: 1;
			color: #888b8d;
		}

		&.focus,
		&.has-value {
			label {
				transform: translateY(-0.8rem);
				background: transparent;
				color: $grey;

				@include media($medium) {
					transform: translateY(-1.8rem);
					padding: 0;
				}
			}
		}
	}

	#{$b}__label {
		padding: 0;

		@include media($medium) {
			padding: 0 0.5rem;
		}
	}

	&__button {
		width: 100%;
		margin: 0;
		padding: 0.82rem 2rem;

		@include media($medium) {
			padding: 0.7rem 2rem;
		}
	}

	span.error,
	.mce_inline_error,
	.response {
		@extend %fs-11;
		position: absolute;
		margin: 5px 0 0;
		line-height: 1.1;
		text-transform: uppercase;
		color: $error;

		@include media($medium) {
			margin-top: 12px;
		}
	}
}
