// pad-col - helper function for generating padding equal to a column, even in nested containers
@function pad-col($paddingColsRequired, $totalCols) {
	$percentage: ($paddingColsRequired / $totalCols) * 100%;
	@return $percentage;
}

// contain floats
@mixin clearfix() {
	*zoom: 1;

	&:before,
	&:after {
		display: table;
		content: '';
	}

	&:after {
		clear: both;
	}
}

// visually hidden
@mixin visuallyhidden() {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

// Stripping out units
@function strip-unit($number) {
	@if type-of($number) == 'number' and not unitless($number) {
		@return $number / ($number * 0 + 1);
	}

	@return $number;
}

@mixin link(
	$link: $black,
	$text-decoration: none,
	$border-bottom-color: transparent
) {
	font-family: $base-font-family;
	border-bottom: solid 1px transparent;
	transition: all $base-duration $base-timing;
	text-decoration: $text-decoration;
	color: $link;

	&:hover,
	&:active,
	&:focus {
		color: $link;
	}
}

@mixin button($bg: $primary, $colour: $white, $border-color: $primary) {
	@extend %fs-21;
	border: 2px solid $border-color;
	border-radius: $button-border-radius;
	background: $bg;
	line-height: 1.3;
	transition: all $base-duration $base-timing;
	font-weight: bold;
	text-decoration: none;
	cursor: pointer;
	color: $colour;

	&:hover,
	&:active,
	&:focus {
		background: darken($bg, 10%);
		border-color: darken($bg, 10%);
		color: $colour;
	}
}

@mixin flex-layout($justficy-content: flex-start, $align-items: center) {
	display: flex;
	// flex-wrap: wrap;
	justify-content: $justficy-content;
	align-items: $align-items;
}

@mixin transition(
	$property: all,
	$duration: $base-duration,
	$effect: $base-timing
) {
	&:not(.no-transition) {
		transition: $property $duration $effect;
	}
}

@mixin dropdown-menu(
	$bg: $white,
	$text-align: left,
	$min-width: 190px,
	$max-height: 200px
) {
	@include transition(max-height, 0.3s);
	position: absolute;
	z-index: 100;
	right: 0;
	min-width: $min-width;
	max-height: 0;
	background-color: $bg;
	list-style: none;
	overflow: hidden;
	box-shadow: $box-shadow;
	text-align: $text-align;

	.active & {
		@include transition(max-height, 0.5s);
		max-height: $max-height;
		padding: 0.5rem 0;
	}
}

@mixin tile($name: 'product-card', $width: 50%, $margin: $product-card-margin) {
	flex-grow: 1;
	width: $width;
	padding: $margin;

	.#{$name}__inner {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
	}
}

@mixin absolute-position-left(
	$top: 50%,
	$left: 0,
	$width: 35px,
	$height: 35px,
	$has-bg: 'false'
) {
	content: '';

	position: absolute;
	top: $top;
	left: $left;
	width: $width;
	height: $height;

	@if ($has-bg == 'true') {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
}

@mixin absolute-position-right(
	$top: 50%,
	$right: 0,
	$width: 35px,
	$height: 35px,
	$has-bg: 'false'
) {
	content: '';

	position: absolute;
	top: $top;
	right: $right;
	width: $width;
	height: $height;

	@if ($has-bg == 'true') {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
}

@mixin card-icon-size($size: $product-icon-size) {
	width: $size;
	height: $size;

	@include media($medium) {
		width: ($size * 1.4) - 5px;
		height: ($size * 1.4) - 5px;
	}

	@include media($ipad) {
		width: ($size * 1.4);
		height: ($size * 1.4);
	}
}

@mixin background-image(
	$size: cover,
	$horizontal-position: center,
	$vrtical-position: center
) {
	position: relative;
	background-size: $size;
	background-position: $horizontal-position $vrtical-position;
	background-repeat: no-repeat;
}

// =============================================================================
// Responsive helpers
// =============================================================================

/**
 * Fluid Property Helper
 * Article: https://bit.ly/2MaHaXb

 * Basic font scale:
 * .component { @include fp(font-size, 12, 24); }
 *
 * Width scale with custom breakpoint min/max:
 * .component { @include fp(width, 12, 24, $small, $medium); }
 *
 * Or define a non-linear scale:
 * .component {
 *   @include fp(font-size, 10, 200, $small, $medium, $remove-end: true);
 *   @include fp(font-size, 20, 300, $medium, $large, $remove-start: true, $remove-end: true);
 *   @include fp(font-size, 30, 400, $large, $max, $remove-start: true);
 * }
 */
@mixin fp(
	$property,
	$min-value,
	$max-value,
	$start-breakpoint: $small,
	$end-breakpoint: $max,
	$source-unit: vw,
	$output-unit: px,
	$remove-start: false,
	$remove-end: false
) {
	$min-value: strip-unit($min-value);
	$max-value: strip-unit($max-value);
	$start-breakpoint: strip-unit($start-breakpoint);
	$end-breakpoint: strip-unit($end-breakpoint);

	$multiplier: ($max-value - $min-value) /
		($end-breakpoint - $start-breakpoint) * 100;
	$adder: ($min-value * $end-breakpoint - $max-value * $start-breakpoint) /
		($end-breakpoint - $start-breakpoint);

	$formula: calc(#{$multiplier + $source-unit} + #{$adder + 0+$output-unit});

	// Start
	// Capped from just before $start-breakpoint
	// Fluid from $start-breakpoint to just before $end-breakpoint
	@if ($remove-end) and not ($remove-start) {
		@media (max-width: #{$start-breakpoint - 1px}) {
			#{$property}: $min-value + 0#{$output-unit};
		}
		@media (min-width: #{$start-breakpoint + 0px}) and (max-width: #{$end-breakpoint - 1px}) {
			#{$property}: $formula;
		}

		// Middle stretch
		// Fluid from $start-breakpoint to just before $end-breakpoint
	} @else if ($remove-start) and ($remove-end) {
		@media (min-width: #{$start-breakpoint + 0px}) and (max-width: #{$end-breakpoint - 1px}) {
			#{$property}: $formula;
		}

		// End
		// Fluid from $start-breakpoint to just before $end-breakpoint
		// Capped from $end-breakpoint
	} @else if ($remove-start) and not ($remove-end) {
		@media (min-width: #{$start-breakpoint + 0px}) and (max-width: #{$end-breakpoint - 1px}) {
			#{$property}: $formula;
		}
		@media (min-width: #{$end-breakpoint + 0px}) {
			#{$property}: $max-value + 0#{$output-unit};
		}

		// Full stretch
		// Capped from just before $start-breakpoint
		// Fluid from $start-breakpoint to just before $end-breakpoint
		// Capped from $end-breakpoint
	} @else {
		@media (max-width: #{$start-breakpoint - 1px}) {
			#{$property}: $min-value + 0#{$output-unit};
		}
		@media (min-width: #{$start-breakpoint + 0px}) and (max-width: #{$end-breakpoint - 1px}) {
			#{$property}: $formula;
		}
		@media (min-width: #{$end-breakpoint + 0px}) {
			#{$property}: $max-value + 0#{$output-unit};
		}
	}
}

// breakpoints
@mixin media($point) {
	@media screen and (min-width: $point) {
		@content;
	}
}

@mixin media-max($point) {
	@media screen and (max-width: $point - 1px) {
		@content;
	}
}

@mixin media-span($from, $to) {
	@media screen and (min-width: $from) and (max-width: $to - 1px) {
		@content;
	}
}

// Slick slider preloader
// use with media queries to match slider breakpoint settings
@mixin carouselPreloader($n) {
	display: none;
	@for $i from 1 through $n {
		&:nth-child(#{$i}) {
			display: flex;
		}
	}
}
