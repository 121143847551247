.imageTextColumn {
	$b: #{&};

	&__image,
	&__text {
		margin-bottom: 1rem;
	}

	.-imageright {
		@include media($small) {
			padding-right: 1rem;
		}
	}
	.-imageleft {
		@include media($small) {
			padding-left: 1rem;
		}
	}

	@include media-max($medium) {
		&__image {
			order: -1 !important;
		}
	}

	h2,
	h3,
	h4 {
		margin: 1em 0;
	}

	h1 + h2,
	h2 + h3 {
		margin-top: -1.5em;
	}

	h3 + h4 {
		margin-top: -1em;
	}

	ul,
	ol {
		margin-left: 1rem;

		li {
			margin-bottom: 0.5rem;
		}
	}
	figure {
		margin: 0;
	}
}
