/*  ==========================================================================
	# Richtext
	======================================================================= */

.wysiwyg,
.summary {
	margin: 0 auto;
	max-width: 900px;
	padding: 0 0 1rem;
	text-align: left;
	pointer-events: all;
	line-height: $reduced-line-height;

	&__title {
		@extend %fs-55;
		font-weight: bold;
		margin-bottom: 1rem;
	}
	> *:first-child {
		margin-top: 0;
	}
	> *:last-child {
		margin-bottom: 0;
	}

	a {
		@include link();
		border-bottom: 1px solid;
		// transition: all 250ms ease-in-out;
		position: relative;

		&:visited {
			transition: all 250ms ease-in-out;
			color: $visited-gray;
		}

		&:focus,
		&:hover {
			margin: 0 -0.25rem;
			padding: 0 0.25rem;
			background: $secondary;
			color: $white;
		}

		&:active {
			transition: all 250ms ease-in-out;
			color: $active-link-primary;
			border-top: none;
			border-right: none;
			border-bottom: 1px solid;
			border-left: none;
			outline: none;
			background: none;
		}
	}

	figure {
		max-width: 600px;
		&.image- {
			&center {
				margin-inline: auto;
			}
			&left {
				margin-right: auto;
			}
			&right {
				margin-left: auto;
			}
		}
	}

	blockquote {
		@extend %fs-30;
		position: relative;
		margin: 4rem 0;
		color: $dark-grey;
		padding-top: 3rem;
		padding-bottom: 3rem;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: -2px;
			width: 0;
			height: 0;
			border: solid 24px transparent;
			border-top-color: $primary;
			border-left-color: $primary;
			border-radius: 5px;
			@include media-max($medium) {
				border: solid 16px transparent;
				border-top-color: $primary;
				border-left-color: $primary;
			}
		}
		&::after {
			content: '';
			position: absolute;
			right: 2px;
			bottom: 0;
			width: 0;
			height: 0;
			border: solid 24px transparent;
			border-right-color: $primary;
			border-bottom-color: $primary;
			border-radius: 5px;
			@include media-max($medium) {
				border: solid 16px transparent;
				border-right-color: $primary;
				border-bottom-color: $primary;
			}
		}
	}
	p {
		@extend %fs-18;
		line-height: 26px;
		font-weight: normal;
	}
	p.lead {
		@extend %fs-24;
		line-height: 34px;
	}
	p.caption {
		@extend %fs-14;
		line-height: 18px;
	}
	p.small {
		@extend %fs-16;
		line-height: 24px;
	}
	p.tiny {
		@extend %fs-11;
		line-height: 16px;
	}

	h2,
	h3,
	h4 {
		margin: 1em 0;
	}

	h1 + h2,
	h2 + h3 {
		margin-top: -1.5em;
	}

	h3 + h4 {
		margin-top: -1em;
	}

	h2 {
		@extend %fs-36;
		line-height: 45px;
	}

	h3 {
		@extend %fs-30;
		line-height: 36px;
	}

	h4 {
		@extend %fs-26;
		line-height: 28px;
	}

	h5 {
		@extend %fs-21;
		line-height: 26px;
	}

	h6 {
		@extend %fs-24;
	}

	ul li {
		list-style: outside;
	}

	ul,
	ol {
		@extend %fs-18;

		li {
			margin-bottom: 1rem;
			margin-left: 1.5rem;
			ul {
				margin-top: 1rem;
			}
		}
	}
	figure {
		margin: 2rem 0;
	}
}
