/*  ==========================================================================
	# Cards
	======================================================================= */

.cards {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 -0.5rem;

	@include media($ipad) {
		margin: 0 -1.5rem;
	}

	> div {
		margin: 1rem 0;
	}
}

.card {
	display: flex;
	position: relative;
	height: 100%;
	margin: 0 0.5rem;
	flex-direction: column;
	border-radius: 0 44px 0 0;
	line-height: $card-line-height;
	overflow: hidden;
	@include media($ipad) {
		margin: 0 1.5rem;
	}

	&.-has-links {
		padding-bottom: 65px;
	}

	@at-root a#{&} {
		color: $black;
		background-color: $white;
	}

	&__image {
		img {
			display: block;
			object-fit: cover;
			&.-icon-size {
				height: unset !important;
				margin: 0.5rem 0.5rem 0;
				min-width: 0% !important;
				max-width: 25%;
			}
			// &[src$="svg"] {
			// 	margin: 0.5rem 0.5rem 0 ;
			// }
		}
	}
	&__titleHolder {
		padding: 1rem;
	}
	&__title {
		@extend %fs-16;
		line-height: 1.1;
		font-weight: bold;
	}
	&__title.-bigger {
		@extend %fs-20;
	}
	&__content {
		@extend %fs-16;
		padding: 1rem;
		flex-grow: 1;
	}
	&__titleHolder + &__content {
		padding-top: 0;
	}
	&__link {
		@extend %fs-16;
		display: block;
		padding: 1rem 1rem 0.75rem;
		font-weight: bold;
		flex-grow: 1;
		background-color: $grey;
	}
	&__content + &__link {
		flex-grow: 0;
	}
	&.-no-height-adjust {
		height: unset;
	}
	&.-orange-border {
		border: solid 2px $primary;
	}
	&__staffName {
		@extend %fs-20;
		padding: 0.5rem 0.5rem 0;
		font-weight: 400;
	}
	&__staffRole {
		@extend %fs-16;
		padding: 0 0.5rem;
		color: $dark-grey;
		line-height: 1;
		text-transform: uppercase;
	}
	&__staffDescription {
		@extend %fs-16;
		padding: 1rem 0.5rem;
	}
	&__staffLinks {
		display: flex;
		position: absolute;
		bottom: 0;
		justify-content: left;
		margin-left: 0.5rem;
	}
	&__staffLink {
		pointer-events: all;
		> img {
			@include transition();
			width: 44px;
			margin: 0.3rem 0.3rem 0.3rem 0;
			padding: 0.3rem;
			border-radius: 0 8px 0 0;
			&:hover {
				color: $black;
				background-color: $white;
			}
		}
	}
}

.side-bar {
	&.-no-spacer {
		@include media($medium) {
			position: absolute;
			right: 0;
		}
	}
}

// 2 COL CARDS

.-doubleColumnCard {
	.card {
		position: relative;
		border-radius: 0 20px 0 0;
		&__title {
			@extend %fs-26;
			font-weight: bold;
			margin-right: 1.6rem;
		}
		&__image {
			display: flex;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			> img {
				min-width: 100%;
			}
		}
		&.-no-img {
			padding-left: 0;
			padding-right: 35%;
		}
		&.-icon-card {
			padding-left: 12%;
		}
		&::before {
			content: '';
			position: absolute;
			top: 15px;
			right: 15px;
			width: 0;
			height: 0;
			border: solid 16px transparent;
			border-top-color: $primary;
			border-right-color: $primary;
			border-radius: 5px;
			z-index: 1;
		}
	}
}

@include media-max($small) {
	.-doubleColumnCard {
		.card {
			padding-left: 0;
			&__image {
				position: relative;
				> img {
					height: unset;
				}
			}
			&.-no-img {
				padding-right: 0;
			}
			&.-icon-card {
				padding-left: 0;
			}
		}
	}
}

@include media($small) {
	.-doubleColumnCard {
		.card {
			padding-left: 35%;
			&__image {
				width: 35%;
			}
		}
	}
}

@include media($ipad) {
	.-doubleColumnCard {
		.card {
			padding-left: 40%;
			&__image {
				width: 45%;
			}
		}
	}
	.card.-icon-card {
		padding-left: 0;
		&__image {
			width: calc(45% + 0.5px);
		}
	}
}

@include media($large) {
	.-doubleColumnCard {
		.card {
			padding-left: 40%;
			&__image {
				width: 42%;
			}
		}
	}
}

.-quadColumnCard {
	.card {
		@include media($large) {
			&__image {
				img {
					max-height: 200px;
					min-width: 100%;
				}
			}
		}
	}
}

// Staff Card Breakpoints

.-doubleColumnStaffCard {
	.card {
		border-radius: 0 20px 0 0;
		position: relative;
		&__image {
			display: flex;
			overflow: hidden;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			img {
				border-radius: 0;
				min-width: 100%;
			}
		}
		&.-no-img {
			padding-left: 0;
			padding-right: 35%;
		}
		&::before {
			content: '';
			position: absolute;
			top: 15px;
			right: 15px;
			width: 0;
			height: 0;
			border: solid 16px transparent;
			border-top-color: $primary;
			border-right-color: $primary;
			border-radius: 5px;
		}
	}
}

@include media-max($small) {
	.-doubleColumnStaffCard {
		.card {
			padding-left: 0;
			&__image {
				position: relative;
			}
			&__staffName {
				padding-right: 3rem;
			}
			&__staffRole {
				padding-right: 2rem;
			}
			&__staffDescription {
				line-height: 1.2;
			}
		}
	}
}

@include media($small) {
	.-doubleColumnStaffCard {
		.card {
			padding-left: 35%;
			&__image {
				width: 35%;
			}
		}
	}
}

@include media($ipad) {
	.-doubleColumnStaffCard {
		.card {
			padding-left: 40%;
			&__image {
				width: 42%;
			}
		}
	}
}
