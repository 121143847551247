.ContactForm {
	&__link {
		color: $black;
		font-weight: 700;
		&:hover,
		:focus {
			filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.3));
			color: $black;
		}
	}
}

.contactForm {
	overflow: hidden;
	.form {
		select,
		input[type='text'],
		input[type='email'],
		input[type='password'],
		input[type='tel'],
		input[type='date'],
		input[type='time'],
		input[type='number'],
		input[type='url'],
		textarea {
			border-color: $white;
		}
	}

	&__subHeading {
		@extend %fs-18;
		margin: 0;
		text-align: center;
		color: $black;
		font-weight: 400;
	}
	&__heading {
		margin-bottom: 0;
		text-align: center;
		color: $black;
		font-weight: bold;
	}
	&__field--button {
		margin-top: 0;
	}

	&__button {
		width: 100%;
		margin-top: 2rem;
	}
}

.formieForm {
	background-color: $primary;
	.fui {
		&-i {
			max-width: 900px;
			width: 100%;
			font-family: $base-font-family;
		}
		&-checkbox-label,
		&-radio-label,
		&-label,
		&-legend,
		&-instructions {
			color: $black;
		}
		&-checkbox-input {
			&:checked {
				+ label::before {
					background-color: #fff;
					background-image: url(../images/check-regular.png);
					background-size: 100%;
				}
			}
		}
		&-radio-input {
			&:checked {
				+ label::before {
					background-color: #fff;
					background-image: url(../images/check-regular.png);
					background-size: 70%;
				}
			}
		}
		&-radio,
		&-checkbox {
			&:not(:last-child) {
				margin-bottom: 8px;
			}
		}
		&-radio-label,
		&-checkbox-label {
			min-height: 30px;
			padding-left: 42px;

			&::before {
				top: 0;
				height: 30px;
				width: 30px;
				background-color: #fff;
			}
		}
		&-input {
			&[type='file'] {
				cursor: pointer;
			}
			border-radius: 0 12px 0 0;
			border: 2px solid $white;
			&.fui-error {
				border-color: $red;
			}
			&::placeholder {
				color: #60636b;
			}
		}
		&-error-message {
			background-color: $red;
			padding: 6px 10px;
			color: $black;
			margin: 0;
		}
		&-btn {
			background-color: $secondary;
			border-color: $secondary;
			border-radius: 0 12px 0 0;
			text-transform: uppercase;
			&:focus,
			&:hover {
				border-color: $white;
				background-color: $white;
				color: $black;
			}
		}
		&-heading {
			font-weight: bold;
		}
		&-alert {
			&-error {
				background-color: $red;
				color: $black;
			}
		}
	}
}
