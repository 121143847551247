/*  ==========================================================================
	## NOTIFICATIONS BAR
	======================================================================= */
.notificationBar {
	$b: #{&};

	position: fixed;
	right: 0;
	bottom: 72px;
	left: 0;
	transition: max-height 550ms ease-in-out;
	background-color: $error;
	max-height: 0;
	z-index: 9998;

	@include media($medium) {
		bottom: 0;
	}

	&__message {
		@extend %fs-16;
		line-height: 1.3;
		max-width: 800px;
		padding: 1rem 0 2.5rem;
		color: $white;
		@include media($medium) {
			padding: 1rem 50px 1rem 0;
		}
	}
	&__link {
		color: $white;
		font-weight: bold;
		text-decoration: underline;
		&:hover,
		&:focus {
			color: $white;
			filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5));
		}
	}
	&__closeModule {
		cursor: pointer;
		display: flex;
		position: absolute;
		top: 0;
		right: 0;
		height: 44px;
		padding: 0 0.75rem;
		color: $white;
		transform: translateY(-90%);
		background-color: $error;
		border-radius: 0 16px 0 0;
		align-items: center;
	}
	&__closeText {
		@extend %fs-14;
		padding: 0.3rem 1rem 0.5rem 0;
		@include media-max($small) {
			display: none;
		}
		
		&:before {
			content: 'Open notification';
		}
		#{$b}.-reveal &:before {
			content: 'Close notification';
		}
	}
	&__closeIcon {
		cursor: pointer;
		font-size: 44px;
		color: $white;
		// @include media ($small) {
		// 	font-size: 44px;
		// }
	}
	&.-reveal {
		max-height: 210px;
		@include media($medium) {
			max-height: 100px;			
		}
	}
}
