.block {
	.sitemap {
		&__main-menu {
			margin-left: 0;
		}

		&__main-list {
			list-style: none;
		}

		&__sub-menu {
			margin: 0 0 0 1rem;
		}

		&__sub-list {
			list-style: none;
		}
	}
}
