$radio-check-size: 35px;
$form-label-color: $black;
$form-label-color--active: $mid-grey;
$form-disabled: lighten($grey, 10);
$form-border-color: $grey;
$form-field-border: 2px solid $form-border-color;

.form {
	@extend %fs-base;
	text-align: left;

	//#region common label and inputs
	label {
		@include transition();
		display: block;
		position: absolute;
		z-index: 1;
		left: 1rem;
		line-height: 22px;
		color: $form-label-color;
	}
	label[for='name']:before {
		@extend %fs-21;
		content: '\f007';
		padding-right: 0.5rem;
		font-family: 'Font Awesome 5 Pro';
		font-weight: 300;
		color: $primary;
		vertical-align: -2px;
	}
	label[for='email']:before {
		@extend %fs-21;
		content: '\f0e0';
		padding-right: 0.5rem;
		font-family: 'Font Awesome 5 Pro';
		font-weight: 300;
		color: $primary;
		vertical-align: -2px;
	}
	label[for='message']:before {
		@extend %fs-21;
		content: '\f4a6';
		padding-right: 0.5rem;
		font-family: 'Font Awesome 5 Pro';
		font-weight: 300;
		color: $primary;
		vertical-align: -2px;
	}
	label.error {
		position: static;
		padding-top: 0.5rem;
		padding-left: 1rem;
		font-size: 12px;
		color: $error;
		text-transform: uppercase;
	}

	select,
	input[type='text'],
	input[type='email'],
	input[type='password'],
	input[type='tel'],
	input[type='date'],
	input[type='time'],
	input[type='number'],
	input[type='url'],
	textarea {
		@include flex-layout();
		@include transition();
		position: relative;
		width: 100%;
		padding: 0.65rem 1rem;
		border: solid 1px $form-border-color;
		border-radius: 0 16px 0 0;
		background-color: $white;
		font-family: $base-font-family;
		line-height: 22px;

		&:hover:not(:disabled) {
			cursor: pointer;
		}

		&:focus {
			outline: 0;
		}

		&[disabled] {
			border-color: $form-disabled;
			background-color: $form-disabled;
			cursor: auto;
		}

		&.error {
			border-color: $error;
		}

		&.datepicker {
			padding-right: 2rem;
		}
	}
	textarea {
		resize: vertical;
		min-height: 126px;
	}
	//#endregion

	//#region field common
	&__field {
		position: relative;
		margin: 2rem 0 0;
		@include media($medium) {
			margin: 2rem 1rem 0;
		}
		&.has-error {
			border-left: 5px solid $black;
		}
	}

	//#endregion

	//#region text type fields
	&__field--text,
	&__field--textarea,
	&__field--date {
		label:not(.error) {
			top: 0.67rem;
			padding: 0 1rem 0 0;
			color: $form-label-color;
		}

		&.focus,
		&.has-value {
			label:not(.error) {
				top: 0;
				padding: 0.2rem 0.5rem;
				background: #fff;
				border-radius: 4px;
				color: #000;
				font-size: 14px;
				transform: translate(-0.5rem, -50%);
				font-weight: bold;
			}
		}
	}

	&__field--small,
	.small-field {
		max-width: 100%;

		@include media($nexus) {
			max-width: 70%;
		}
	}

	//#endregion

	//#region checkbox list and radio button list
	&__field--checkboxlist,
	&__field--radiolist {
		input {
			position: absolute;
			left: -9999px;

			&:checked ~ label {
				border-color: $form-label-color;
				color: $form-label-color;

				&::after {
					opacity: 1;
					transform: scale(0.89);
					border-color: $tertiary;
				}
			}
		}

		label {
			@extend %fs-16;
			position: relative;
			left: 0;
			min-height: $radio-check-size;
			padding: 8px 0 5px 45px;
			cursor: pointer;
			line-height: 1.2;
			color: $form-label-color;

			&::before {
				@include absolute-position-left(
					50%,
					0,
					$radio-check-size,
					$radio-check-size,
					'false'
				);
				display: block;
				margin-top: -16px;
				border: $form-field-border;
				background: $white;
			}

			&::after {
				@include absolute-position-left(
					50%,
					0,
					$radio-check-size,
					$radio-check-size,
					'true'
				);
				display: inline-block;
				margin-top: -16px;
				opacity: 0;
				transform: scale(0.3);
				transition: all 0.3s;
				text-align: center;
				line-height: $radio-check-size;
				color: $primary;
			}

			&:hover::before {
				border-color: $form-border-color;
				color: $form-label-color--active;
			}
		}

		&.small label {
			padding-bottom: 0;
			padding-top: 7px;
			padding-left: 34px;
		}
	}

	&__field--checkboxlist {
		margin: 1rem 0.5rem;

		label {
			&::after {
				left: 5px;
				top: calc(50% - 5px);
				background-image: url('../images/check-regular.png');
			}
		}
	}

	&__field--radiolist {
		&--inline {
			display: inline-block;
		}

		label {
			margin-bottom: 1.3rem;

			&::before {
				border-radius: 50%;
			}

			&::after {
				background-image: url('../images/circle.png');
			}
		}
	}

	//#endregion

	//#region datepicker
	&__field--datepicker {
		position: relative;
		cursor: pointer;

		&::before {
			@include absolute-position-right(50%, 1rem, 1.2rem, 2rem, 'true');
			padding-left: 1rem;
			pointer-events: none;
			z-index: 11;
			transform: translateY(-50%);
			background-size: contain;
			background-image: url('../images/calendar.png');
		}
	}
	//#endregion

	//#region file
	&__field--file {
		position: relative;

		&.focus {
			label {
				@extend %small;
				transform: translateY(-1.8rem);
				color: $form-label-color--active;
				background: white;
			}
		}

		label {
			position: relative;
			left: 0;
			padding: 0.65rem 1rem;
			border: 2px solid $form-border-color;
			border-radius: 0;
			background-color: $white;
			cursor: pointer;

			&::before {
				@include absolute-position-right(
					50%,
					1rem,
					1.2rem,
					2rem,
					'true'
				);
				transform: translateY(-50%);
				background-size: contain;
				background-image: url('../images/paperclip.png');
			}

			&.has-value-upload-label {
				@include transition();
				position: absolute;
				top: -2px;
				left: 0;
				opacity: 0;
				margin: 0 1rem;
				padding: 0 0.5rem;
				z-index: 11;
				border: none;
				font-size: 14px;
				&::before {
					content: none;
				}
			}
		}

		input[type='file'] {
			position: absolute;
			left: 9999px;
			opacity: 0;
			width: 0;
			height: 0;
			&:focus + label {
				font-weight: bold;
				text-decoration: underline;
			}
		}

		&.has-value {
			label {
				color: $white;
			}

			.has-value-upload-label {
				background-color: transparent;
				opacity: 1;
				transform: translateY(8px);
				font-size: 11px;
				color: $dark-grey;
			}
		}
	}

	//#endregion

	//#region select
	&__field--select {
		position: relative;

		&::after {
			// fal fa-chevron-down
			content: '\f078';
			display: block;
			position: absolute;
			top: 23px;
			right: 15px;
			pointer-events: none;
			font-family: 'Font Awesome 5 Pro';
			font-size: 15px;
			text-align: center;
			transform: translateY(-50%);
		}
	}

	.selected-file {
		position: absolute;
		top: 0.6rem;
		right: 0;
		left: 1rem;
		margin-right: 1.6rem;
		background-color: $white;
		z-index: 1;
		color: $black;
		pointer-events: none;

		&__name {
			display: block;
			width: 190px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			@include media($nexus) {
				width: 260px;
			}

			@include media($nexus) {
				width: 230px;
			}

			@include media($ipad) {
				width: 260px;
			}
		}

		&__button {
			position: absolute;
			top: 0;
			right: -0.7rem;
			margin-left: 0.5rem;
			pointer-events: all;
			cursor: pointer;
		}

		&__icon {
			color: $red;
		}
	}

	//#endregion

	//#region button
	&__button {
		margin: 0.5rem 0;
	}
	//#endregion

	//#region etc
	legend,
	&__title {
		@extend %fs-18;
		margin-top: 2rem;
		font-weight: bold;
	}

	.radio-label {
		@extend legend;
		position: relative;
		margin-left: -0.5rem;
	}

	.form__select {
		padding: 1rem 1.5rem;
	}

	span.help,
	span.error,
	.instruction,
	.errors,
	.ff-errors,
	.ff-form-errors,
	.invalid-feedback {
		@extend %fs-14;
		display: block;
		line-height: 1.1;
		padding: 0.5rem;
		color: $black;
		max-width: 100%;

		> li {
			list-style: none;
		}
	}
	.form__recaptcha {
		.errors {
			max-width: 304px;
			margin: -8px auto 0;
		}
	}

	.instruction {
		@extend %fs-14;
		margin: 0 1rem 1rem 0.5rem;
		line-height: 1.7;
		color: $black;
	}

	.small {
		@extend %small;
	}
	//#endregion

	.ff-form-success p,
	.alert {
		max-width: 100%;
		margin: 0;
		margin-top: 1rem;
		padding: 0.5rem;
		color: $black;
		background-color: $white;
	}
	.alert.alert-danger,
	.alert.form-errors {
		background-color: $error;
	}

	&__field--button {
		margin: 0.5rem 0;
	}

	&__result {
		position: absolute;
		margin-left: 20px;
		color: red;

		.errors {
			margin: 12px 0 0 20px;
		}
	}
	&__recaptcha {
		text-align: center;
		&.-left {
			text-align: left;
			margin-left: 0;
		}
		.g-recaptcha {
			display: inline-block;
		}
	}
}

.contactForm.panel {
	padding-top: 0;
	background-color: $primary;
}

.applicationForm.panel {
	padding-top: 0;
	background-color: $grey;
}

/* ===================================================================
	# Disable browser specific styles
	================================================================ */

* {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

select::-ms-expand {
	display: none;
}

select:-moz-focusring {
	text-shadow: 0 0 0 #000;
	/* your normal text color here */
	color: transparent;
}

select:-moz-focusring * {
	text-shadow: none;
	color: #000;
	/* your normal text color here */
}

input:-internal-autofill-selected {
	background-color: transparent !important;
}

/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}

input[type='search']::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	display: none;
}
