.video {
	&__block {
	}

	&__container {
		position: relative;
		display: block;
		width: 100%;
		overflow: hidden;
		&::before {
			display: block;
			content: '';
			padding-top: 56.25%;
		}
	}

	&__player {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
}
