/*  ==========================================================================
	# HERO BANNER
	======================================================================= */

.block__halfHeroBanner {
	height: 100%;
	padding: 0;

	@include media-max($ipad) {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		height: 100px;
		overflow: hidden;
		@include media ($medium) {
			height: 140px;
		}
	}

}

@include media($ipad) {
	.panel__halfHeroBanner {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		height: 400px;
		padding: 0;
		overflow: hidden;

		&.-background {
			z-index: 9;
			@include transition(transform, 1s);

			.-scrolled & {
				transform: translateY(calc(-400px + 140px));
			}
		}

		+ .main {
			padding-top: 2rem;
			margin-top: 0;
			transform: translateY(400px);
			@include transition(transform, 1s);

			.-scrolled & {
				transform: translateY(0);
			}
		}
	}
}
@include media($ipad) {
	.panel__halfHeroBanner {
		height: 450px;

		&.-background {
			.-scrolled & {
				transform: translateY(calc(-450px + 140px));
			}
		}
		+ .main {
			transform: translateY(450px);
		}
	}
}

.halfHeroBanner {
	z-index: 1;
	position: relative;
	height: 100%;
}
