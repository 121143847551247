/* ----- Responsive videos ----- */
.embed-container,
.video-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	margin: 0 0 $small-spacing;
	font-size: 16px;

	iframe,
	object,
	embed,
	.google-map {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

/* ----- No / Only ----- */
.-noPrint {
	@media print {
		display: none !important;
	}
}
.-onlyPrint {
	@media screen {
		display: none !important;
	}
}
.-noWrap {
	white-space: nowrap;
}
.-isHidden {
	display: none !important;
}

.-sibling-no-pointer-events + .panel{
	pointer-events: none;
}
/* ----- Accessibility ----- */
/**
* Visually hide an element, but leave it available for screen readers
* @link https://github.com/h5bp/html5-boilerplate/blob/master/dist/css/main.css
* @link http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
*/
.screen-reader {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
	clip: rect(0 0 0 0);
	overflow: hidden;
}

/**
* Extends the .screen-reader class to allow the element to be focusable when navigated to via the keyboard
* @link https://github.com/h5bp/html5-boilerplate/blob/master/dist/css/main.css
* @link https://www.drupal.org/node/897638
*/
.screen-reader-focusable:active,
.screen-reader-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	margin: 0;
	clip: auto;
	overflow: visible;
}

/* ----- Bg Colours ----- */
@each $property, $value in $colors {
	.bg {
		&--#{$property} {
			background-color: $value;
		}
	}
}

.bg--black {
	color: $white;

	a {
		color: $white;
	}
}
