/*  ==========================================================================
	# Font Sizes
	======================================================================= */
// Mina note - Need to adjust font size later
// banner message
%fs-64 {
	@include fp(
		'font-size',
		24,
		40,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		40,
		50,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		50,
		64,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
}

// intro h1 - font-weight normal
%fs-55 {
	@include fp(
		'font-size',
		28,
		38,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		38,
		40,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		40,
		50,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
}

%fs-46 {
	@include fp(
		'font-size',
		28,
		34,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		34,
		40,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		40,
		45,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
}

%fs-36 {
	@include fp(
		'font-size',
		26,
		30,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		30,
		34,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		34,
		37,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
}

%fs-30 {
	@include fp(
		'font-size',
		24,
		26,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		26,
		28,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		28,
		31,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
}

// h4, button
%fs-28 {
	@include fp(
		'font-size',
		20,
		22,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		22,
		24,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		24,
		28,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
}

// h5, product list title
%fs-26 {
	@include fp(
		'font-size',
		18,
		20,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		20,
		23,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		23,
		26,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
}

// h6, FEATURED CATEGORIES's title
%fs-24 {
	@include fp(
		'font-size',
		17,
		19,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		19,
		20,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		20,
		24,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
}

%fs-21 {
	@include fp(
		'font-size',
		15,
		16,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		16,
		16,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		16,
		21,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
}

// Product-card details, normal
%fs-20 {
	@include fp(
		'font-size',
		16,
		18,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		18,
		18,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		18,
		20,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
}

// NEWS ALERTS's text, Accreditations's title, intro or summary? font-weight:normal
%fs-18 {
	@include fp(
		'font-size',
		17,
		17,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		17,
		17,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		17,
		18,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
}

// body, category menu, product-card's tooltip, button, toast-message
%fs-16,
%fs-base,
%base {
	@include fp(
		'font-size',
		15,
		15,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		15,
		16,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		16,
		16,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
}

// Top nav, FEATURED CATEGORIES's desc,
%fs-15,
%fs-small,
%small {
	@include fp(
		'font-size',
		14,
		15,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		15,
		15,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		15,
		15,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
}

// footer menu
%fs-13 {
	@include fp(
		'font-size',
		12,
		13,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		13,
		13,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		13,
		14,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
}

// category-card's title, footer, breadcrumb
%fs-14 {
	@include fp(
		'font-size',
		13,
		14,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		14,
		14,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		14,
		14,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
}

// footer small font
%fs-11 {
	@include fp(
		'font-size',
		12,
		12,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		12,
		12,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		12,
		12,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
}

html {
	font-size: 17px;
}

/*  ==========================================================================
	# Other typography
	======================================================================= */

%caps {
	font-family: $base-font-family;
	text-transform: uppercase;
	letter-spacing: 0.25em;
}

body {
	color: $base-font-color;
	font-family: $base-font-family;
	font-weight: $base-font-weight;
	line-height: $base-line-height;
	margin: 0;
}

b,
strong,
.strong {
	font-family: $heading-font-family;
	font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $heading-font-family;
	margin: $base-spacing 0 ($base-spacing / 2) 0;
	color: $black;
	font-weight: 400;
}

h1 {
	@extend %fs-46;
	line-height: 50px;
}

h2 {
	@extend %fs-36;
	line-height: 45px;
}

h3 {
	@extend %fs-30;
	line-height: 36px;
}

h4 {
	@extend %fs-26;
	line-height: 28px;
}

h5 {
	@extend %fs-21;
	line-height: 26px;
}

h6 {
	@extend %fs-24;
}

p {
	@extend %fs-18;
	line-height: 26px;
	font-weight: normal;
}

ol,
ul {
	@extend %fs-18;
}

p.lead {
	@extend %fs-24;
	line-height: 34px;
}
p.caption {
	@extend %fs-14;
	line-height: 18px;
}
p.small {
	@extend %fs-16;
	line-height: 24px;
}
p.tiny {
	@extend %fs-11;
	line-height: 16px;
}

a {
	@include link();
}

hr {
	border-bottom: $base-border;
	border-left: 0;
	border-right: 0;
	border-top: 0;
	margin: $base-spacing 0;

	&.-large {
		border-bottom-width: calc(3 * #{$base-border-width});
		border-bottom-color: $tertiary;
	}
}

.hr-row.row {
	@include media-max($ipad) {
		flex-direction: column;
	}
}

img,
picture {
	margin: 0;
	max-width: 100%;
}

ul,
ol {
	margin: 0;
	padding: 0;

	&%default-ul {
		list-style-type: disc;
		margin-bottom: $small-spacing;
		padding-left: $base-spacing;
	}

	&%default-ol {
		list-style-type: decimal;
		margin-bottom: $small-spacing;
		padding-left: $base-spacing;
	}
}

dl {
	margin-bottom: $small-spacing;

	dt {
		font-weight: bold;
		margin-top: $small-spacing;
	}

	dd {
		margin: 0;
	}
}

// table {
// 	border-collapse: collapse;
// 	font-feature-settings: 'kern', 'liga', 'tnum';
// 	margin: 0;
// 	width: 100%;
// }

// th {
// 	border-bottom: 1px solid shade($base-border-color, 25%);
// 	font-weight: 600;
// 	padding: $small-spacing 0;
// 	text-align: left;
// }

// td {
// 	border-bottom: $base-border;
// 	padding: $small-spacing 0;
// }

// tr,
// td,
// th {
// 	vertical-align: middle;
// }
