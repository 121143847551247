/*  ==========================================================================
	# Full image
	======================================================================= */
.block__bannerImage {
	padding: 0;
	min-height: 350px;
	.lazy--background {
		min-height: 350px;
	}
}
.bannerImage {
	position: relative;
	&.panel {
		padding: 0;
	}

	.block__bannerImage {
		padding: 0;
		z-index: -1;
		object-fit: cover;

		@include media($small) {
			min-height: 200px;
		}
		.lazy--background {
			height: auto !important;
			padding-bottom: 25% !important;
			@include media-max($ipad) {
				height: auto !important;
				padding-bottom: 40% !important;
				.lazy--background img {
					width: auto !important;
					max-width: none !important;
					transform: translateX(-40%);
				}
			}
			@include media-max($medium) {
				padding-bottom: 65% !important;
			}
			@include media-max($small) {
				padding-bottom: 160% !important;
			}
		}
	}

	&__content {
		display: flex;
		position: absolute;
		align-items: center;
		top: 0;
		bottom: 0;
		padding: 1rem;
		@include media($small) {
			min-height: 200px;
			padding: 0.5rem 0;
		}

		&::before {
			content: '';
			z-index: 0;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: rgba(0, 0, 0, 0.7);
			border-top-right-radius: 90px;
		}
		&::after {
			content: '';
			z-index: 0;
			position: absolute;
			top: 0;
			right: 100%;
			bottom: 0;
			width: 50vw;
			background: rgba(0, 0, 0, 0.32);
		}
	}
	&__title {
		> h2 {
			margin-bottom: 1.5rem;
			color: $white;
			font-weight: 700;
		}
	}
	&__summary {
		margin-bottom: 1.5rem;
		color: $white;
	}

	.vimeo-container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #222; /* Recommend a dark background as vimeo takes a moment to load the video sometimes */
		pointer-events: none;
		z-index: 0;
		overflow: hidden;

		iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100vw;
			height: 60.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
			transform: translate(-50%, -50%);
			z-index: 0;
			min-height: 100vh;
			min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
		}
	}
}
