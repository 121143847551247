.footer {
	@extend %fs-16;
	position: relative;
	padding-bottom: 4rem;
	padding-top: 0;
	@include media($medium) {
		padding-bottom: 0;
	}
}

.footerBottom {
	background-color: $grey;
}

.footerNav {
	display: inline-block;
	padding: 0.5rem 0;

	&__list {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	&__item {
		display: inline-block;
		margin: 0 0.5rem 0 0;
		padding: 0;
	}

	&__link {
		@extend %fs-16;
		font-family: $base-font-family;
		border-bottom: solid 1px transparent;
		color: $black;
		&:hover {
			border-bottom-color: $primary;
		}
	}
}

.footerCopyright {
	@extend %fs-16;
	display: inline-block;
	font-family: $base-font-family;
	margin-bottom: 0.5rem;
}

.socialIcons {
	margin-top: 2rem;
	margin-right: 1rem;

	@include media($ipad) {
		margin-top: 1rem;
	}

	&__link {
		@include transition(color, 0.25s);
		display: inline-block;
		vertical-align: top;
		width: 44px;
		height: 44px;
		text-align: center;
		font-size: 30px;
		line-height: 44px;
		color: $white;

		&:hover,
		&:focus {
			color: $white;
			cursor: pointer;
		}
	}
}

.footerTop {
	background-color: $secondary;
	padding: 2rem 0;
	&__startLogoBox {
		&__image {
			width: 222px;
		}
	}
	&__endLogoBox {
		border-top: 1px solid $white;
		padding-top: 1rem;
		display: flex;
		align-items: center;
		@include media($medium) {
			padding-left: 1.5rem;
			padding-top: 0;
			border-top-width: 0;
			border-left: 1px solid $white;
		}
		&__image {
			width: 176px;
		}
	}
	&__footerLinks {
		padding-bottom: 1rem;
		@include media($medium) {
			padding: 0;
			text-align: right;
		}
	}
}

.locationsLink {
	margin-top: 1rem;
	margin-right: 1.5rem;

	&__link {
		@extend %fs-20;
		color: $white;
		margin-right: 1rem;

		&:last-child {
			margin-right: 0;
		}
		&:hover,
		&:focus {
			color: $white;
			cursor: pointer;
		}
	}
}

.plastykLogo {
	margin: 1rem;
	font-family: Arial, sans-serif;

	&__text {
		letter-spacing: -0.5px;
	}

	&__link {
		@include link(#a6aaa9);
		@include flex-layout(flex-start, flex-end);
		font-size: 11px;
		line-height: 13px;
	}

	&__img {
		margin: 0 3px;
		max-width: 42px;
	}
}
