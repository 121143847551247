/*  ==========================================================================
	# Full image
	======================================================================= */

.fullWidthImage {
	margin: 0;
	padding: 0;

	&.panel {
		padding: 0;
	}

	&__caption {
		@extend %base;
		font-style: italic;
		margin: 0.5em 0 0 0;
		padding: 0;
	}
}
