.carousel {
	position: relative;
	padding: 0 46px;

	&__item {
		margin: 0 0.5rem;
		img {
			margin: auto;
		}
	}
	&__arrow {
		top: 50%;
		transform: translateY(-50%);
		position: absolute;
		border: none;
		background: $primary;
		height: 44px;
		width: 44px;
		border-radius: 22px;
		font-size: 34px;
		line-height: 1;
		color: $white;

		&:hover {
			transition: 0.3s;
			background: darken($primary, 10%);
		}
		&--next {
			right: 0;
			padding-left: 8px;
		}

		&--previous {
			left: 0;
			padding-right: 8px;
		}
	}
}
