/*  ==========================================================================
	# Cards
	======================================================================= */

.block__newsListing {
	margin-bottom: 2rem;
}

.newsCategories {
	list-style: none;
	display: flex;
	margin-bottom: 2rem;
	margin-left: 3px;
	flex-flow: wrap;

	&__heading {
		color: $black;
	}

	&__link {
		@extend %fs-14;
		display: block;
		position: relative;
		padding: 0.1rem 0.75rem;
		margin: 2px 0 0 2px;
		background-color: $white;
		outline: solid 2px $mid-grey;
		color: $black;
		font-family: $heading-font-family;
		width: max-content;

		&:hover,
		&:focus {
			z-index: 1;
		}

		&:hover,
		&:focus {
			outline: solid 2px $black;
		}
	}
}

.newsCard {
	@include transition(all);
	display: flex;
	position: relative;
	width: calc(33.3% - 1rem);
	margin-bottom: 1rem;
	flex-direction: column;
	background-color: $grey;
	outline: solid 3px transparent;
	line-height: $reduced-line-height;
	flex-grow: 0;
	border-radius: 0 44px 0 0;
	// transform: translateZ(0);
	z-index: 1;
	@include media-max($medium) {
		width: calc(50% - 0.5rem);
	}
	@include media-max($small) {
		width: calc(100%);
	}
	&::before {
		transition: all 250ms ease-in-out;
		content: '';
		position: absolute;
		top: -3px;
		right: -3px;
		bottom: -3px;
		left: -3px;
		background-color: $secondary;
		border-radius: 0 44px 0 0;
		z-index: -1;
		opacity: 0;
	}

	&__holder {
		+ hr,
		~ .pagination {
			width: calc(100% - 1rem);
			&__list {
				margin-right: -0.4rem;
			}
		}
	}

	&__image {
		background-color: darken($white, 5%);
		border-radius: 0 44px 0 0;
		overflow: hidden;
		.lazy--background {
			border-radius: 0 44px 0 0;
		}
	}

	&__contents {
		padding: 16px;
		position: relative;
		background-color: $grey;
		&::before {
			--triange-size: 8px;
			content: '';
			position: absolute;
			right: 16px;
			bottom: 16px;
			border-top: var(--triange-size) solid transparent;
			border-left: var(--triange-size) solid transparent;
			border-bottom: var(--triange-size) solid $primary;
			border-right: var(--triange-size) solid $primary;
			width: 0;
			border-radius: 4px;
			height: 0;
		}
	}

	&__category {
		@extend %fs-11;
		margin-bottom: 0.5rem;
	}

	&__date {
		@extend %fs-11;
		margin-top: 1.5rem;
	}

	&__title {
		@extend %fs-20;
		@include transition(all);
		color: $black;
		flex-grow: 1;
		margin: 0;
		line-height: 1.25;
		font-weight: bold;
	}

	@at-root a#{&} {
		color: $black;

		&:hover,
		&:focus {
			z-index: 2;
			&::before {
				transition: all 250ms ease-in-out;
				opacity: 1;
			}
		}

		@media (hover: none) {
			&:hover,
			&:focus {
				outline: solid 3px transparent;
			}
		}
	}
}

.news {
	margin: 2rem 0;

	@include media($medium) {
		margin: 4rem 0;
	}

	&__back {
		color: $black;
		font-weight: 400;
		padding-left: 4px;
		&:hover {
			color: $primary;
		}
	}

	&__info {
		@extend %fs-11;
		display: flex;
		justify-content: space-between;
		padding-bottom: 0.5rem;
		padding-left: 4px;
		margin-bottom: 2rem;
	}

	&__heading.heading {
		margin: 0;
		color: $black;
		line-height: 1;
		padding-top: 0.3rem;
		margin-bottom: 1rem;
	}

	&__image {
		margin-bottom: 2rem;
	}

	&__searchForm {
		display: inline-flex;
		margin-bottom: 1rem;
		width: 100%;
		@include media-max($medium) {
			margin-top: 1rem;
		}
	}

	&__searchInput {
		display: inline-flex;
		width: 65%;
		padding: 0.75rem 1.5rem;
		border: 1px solid rgba(0, 0, 0, 0.5);
		outline: none;

		&:focus-visible {
			outline: 1px dotted $black;
		}
	}

	&__searchButton {
		min-width: 35%;
		border: 2px solid $dark-gray;
		&:hover {
			background: white;
			color: black;
		}
		@include media-max($small) {
			width: min-content;
		}
	}
	hr {
		margin-top: 12px;
		margin-bottom: 6px;
		border-bottom: 1px solid $black;
	}
}

.recentNews {
	& > * {
		@include media($large) {
			margin-left: 2rem;
			width: calc(100% - 2rem);
		}
	}

	&__heading {
		@extend %fs-28;
		margin-top: 0;
	}

	&__list {
		list-style: none;
		margin-top: 1rem;
	}

	&__link {
		display: block;
		padding: 0.25rem 0 0.25rem 1.5rem;
		border-bottom-color: $mid-grey;
		color: $black;
		position: relative;

		&::before {
			content: '\f105';
			display: inline-block;
			position: absolute;
			top: 0.5rem;
			left: 0;
			margin-right: 0.75rem;
			margin-left: 0.25rem;
			color: $primary;
			font-size: 20px;
			font-family: 'Font Awesome 5 Pro';
			font-weight: 700;
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			line-height: 1;
		}

		&:hover {
			border-bottom-color: $black;
		}
	}
}

.gutter-sizer {
	width: 1rem;
}
