/**
 * Include all the block styles for Craft Matrix / Neo fields.
 */

.panel {
	padding: 0;

	@include media($medium) {
		padding: 1.5rem 0;
	}

	@include media($ipad) {
		padding: 2rem 0;
	}

	&.-grey-background {
		background-color: $grey;
	}

	&.-no-padding {
		padding: 0;
	}

	&.-heading {
		padding-bottom: 0;
	}

	&__wysiwyg,
	&__imageTextColumn,
	&__news {
		a:not(.button) {
			position: relative;
			color: $black;
			border-bottom: 1px solid;

			&:focus,
			&:hover {
				margin: 0 -0.25rem;
				padding: 0 0.25rem;
				background: $secondary;
				color: $white;
			}
		}
	}
	&__summary + &__wysiwyg {
		margin-top: -3rem; //match top/bottom padding on both blocks

		@include media($nexus) {
			margin-top: -2rem;
		}
	}
}
.block {
	padding: 1.5rem 0;

	@include media($nexus) {
		padding: 1rem 0;
	}

	&__summary {
		margin-top: 0;

		@include media($medium) {
			margin-top: 2.5rem;
		}
	}

	&.-center {
		text-align: center;
	}

	&.-heading {
		padding: 0;
	}
}
@import 'blocks/addresses';
@import 'blocks/banner-image';
@import 'blocks/blockquote';
@import 'blocks/cards';
@import 'blocks/full-width-image';
@import 'blocks/gallery';
@import 'blocks/hero-banner';
@import 'blocks/half-hero-banner';
@import 'blocks/image-text-column';
@import 'blocks/news';
@import 'blocks/video';
@import 'blocks/wysiwyg';
