.shareIcons {
	position: fixed;
	right: 0;
	bottom: 50%;
	padding: 1.5rem 0 1rem;
	border-radius: 10px 0 0;
	background: $primary;
	z-index: 98;
	@include media-max($medium) {
		display: none;
	}

	@include fp(
		'width',
		50px,
		60px,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'width',
		60px,
		70px,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'width',
		70px,
		82px,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
}

.shareIcons__label {
	@include transition();
	display: block;
	position: absolute;
	top: 7px;
	right: -30px;
	opacity: 0;
	height: 0;
	color: $white;
	font-size: 11px;
	text-transform: uppercase;

	@include media($nexus) {
		font-size: 13px;
	}

	@include media($medium) {
		font-size: 15px;
	}
}

.shareIcons__item {
	color: $white;
	text-decoration: none;
	line-height: 1;

	&:hover,
	&:focus {
		.shareIcons__label {
			text-align: center;
			opacity: 1;

			@include fp(
				'right',
				5px,
				6px,
				$small,
				$medium,
				$remove-start: false,
				$remove-end: true
			);
			@include fp(
				'right',
				6px,
				11px,
				$medium,
				$ipad,
				$remove-start: true,
				$remove-end: true
			);
			@include fp(
				'right',
				11px,
				14px,
				$ipad,
				$max,
				$remove-start: true,
				$remove-end: false
			);
		}

		.shareIcons__icon {
			color: $secondary;
			transform: scale(1.1);
		}
	}
	&:not(:last-of-type) {
		> .shareIcons__icon {
			margin-bottom: 1rem;
		}
	}
}

.shareIcons__icon {
	display: flex;
	position: relative;
	justify-content: center;
	transition: all $base-duration ease;

	@include fp(
		'font-size',
		28px,
		33px,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		33px,
		40px,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		40px,
		44px,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
}
