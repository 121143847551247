/*  ==========================================================================
	# Table
	======================================================================= */

.table__wrapper {
	overflow-x: auto;
	border-top: $base-border-width solid $grey;
}

.table {
	// define block variable
	$b: #{&};

	width: 100%;
	border-bottom: $base-border-width solid $grey;
	text-align: center;

	tr {
		border-color: $grey;
	}

	td {
		border-bottom: none;
	}

	th,
	td {
		min-width: 7rem;
		padding: 0.75rem 1rem;
		text-align: center;

		&#{$b}.-text-left {
			text-align: left;
		}

		&#{$b}.-text-right {
			text-align: rihgt;
		}

		&#{$b}.-text-center {
			text-align: center;
		}
	}

	&__header {
		background-color: $white;
		@extend %fs-18;

		th {
			padding: 1rem;
		}
	}

	&__row-header {
		background-color: $grey;
	}

	&__row {
		@extend %fs-16;

		&.small-font {
			@extend %fs-14;
		}

		&:nth-child(even) {
			background-color: $grey;
		}
	}

	&__header-text {
		padding: 1rem;
		font-weight: bold;
	}

	&__link {
		color: $primary;

		&:hover,
		&:focus {
			color: $black;
		}
	}
}
