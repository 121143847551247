.breadcrumbs {
	background: $white;

	&.panel {
		padding: 0.5rem 0;
	}

	&__list {
		margin: 0;
		padding: 0;
		line-height: 1.5;
		@extend %fs-14;
	}

	&__item {
		display: inline-block;

		&:not(:last-of-type)::after {
			content: '/';
			margin-right: 0.125rem;
			margin-left: 0.125rem;
			color: $secondary;
		}
	}

	&__link {
		background-color: transparent;
		color: $black;
		&:hover {
			border-bottom: solid 1px $tertiary;
		}
	}
}
