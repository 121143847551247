/*  ==========================================================================
	# HERO BANNER
	======================================================================= */

.block__heroBanner {
	height: 100%;
	padding: 0;
}

@include media-max($ipad) {
	.panel__heroBanner {
		&.-background {
			.block__heroBanner {
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				height: 110px;
				@include media($medium) {
					height: 140px;
				}
				.lazy--background {
					padding-bottom: 0 !important;
				}
			}
		}
		&.-content {
			@include media-max ($medium) {
				margin-top: 2rem;
			}
		}
	}
	.vimeo-container {
		position: relative;
		overflow: hidden;

		.heroVideo {
			position: relative;
			width: 100%;
			height: 110px;
			@include media($medium) {
				height: 140px;
			}
		}
	}
}

@include media($ipad) {
	.panel__heroBanner {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		height: 90vh;
		padding: 0;
		overflow: hidden;

		&.-background {
			@include transition(transform, 1s);
			z-index: 9;

			.-scrolled & {
				transform: translateY(calc(-90vh + 140px));
			}
		}

		&.-content {
			z-index: 10;
			transition-property: opacity;
			transition-duration: 250ms;
			transition-timing-function: ease-in-out;
			transition-delay: 1000ms;

			.-scrolled & {
				opacity: 0;
				transition-delay: 0ms;
				pointer-events: none;
			}
		}

		+ .main {
			@include transition(transform, 1s);
			transform: translateY(90vh);

			.-scrolled & {
				transform: translateY(140px);
			}
		}
	}
	.vimeo-container {
		position: relative;
		overflow: hidden;
		height: 100%;

		.heroVideo {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 0;
		}
	}
}

.heroBanner {
	z-index: 1;
	position: relative;
	height: 100%;

	.inner-wrapper,
	.container,
	.row {
		height: 100%;
	}

	&__contentWrapper {
		position: relative;
		z-index: 1;
	}
	&__content {
		position: relative;
		padding: 60px 50px;
		@include media-max($ipad) {
			padding: 1rem 0;
		}

		&::before {
			content: '';
			z-index: -1;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: rgba(0, 0, 0, 0.32);
			border-top-right-radius: 90px;
			@include media-max($ipad) {
				display: none;
			}
		}
		&::after {
			content: '';
			z-index: -1;
			position: absolute;
			top: 0;
			right: 100%;
			bottom: 0;
			width: 50vw;
			background: rgba(0, 0, 0, 0.32);
			@include media-max($ipad) {
				display: none;
			}
		}
	}

	&__title {
		@extend %fs-55;
		margin-bottom: 1.5rem;
		color: $white;
		font-weight: 700;
		line-height: 1;
		@include media-max($ipad) {
			color: $black;
		}
	}

	&__subtitle {
		@extend %fs-30;
		margin-bottom: 1.5rem;
		color: $white;
		line-height: 1.2;
		@include media-max($ipad) {
			color: $black;
		}
	}

	&__button {
		margin-top: 0;
		margin-bottom: 1rem;
		margin-right: 1rem;
		width: 45%;
		padding: 0.7rem 2rem;
		text-align: center;
		
		@include media-max($ipad) {
			margin-top: 1rem;
			margin-right: 0;
			margin-bottom: 0;
			width: 100%;
		}
		&:hover,
		&:focus {
			background-color: $white;
			color: $black;
		}
	}
}
