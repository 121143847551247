/*  ==========================================================================
	# Buttons
	@include button(bg, colour, border-colour)
	Default button(#00B140, #ffffff, #00B140)
	======================================================================= */

.button {

	@extend %fs-18;
	@include button($primary, $black, $primary);
	display: inline-block;
	width: 100%;
	padding: 1rem 1.25rem;
	border: none;
	border-radius: 0 16px 0 0;
	font-weight: 700;

	@include media($small) {
		width: auto;
		padding: 0.7rem 4rem;
	}

	&:focus {
		color: $black;
		outline: none;
		background-color: $secondary;
	}

	&.-small {
		padding: 0.5rem 1rem;
	}
	&.-featured {
		@include button($tertiary, $black, $tertiary);
	}
	&.-text-small {
		@extend %fs-13;
	}
	&.-has-icon {
		@include flex-layout(center, center);
		&::before {
			content: '\f067';
			margin-right: 0.5rem;
			font-family: 'Font Awesome 5 Pro';
		}
	}
	&.-secondary {
		color: $white;
		background-color: $secondary;
		&:focus {
			color: $white;
			background-color: darken($primary, 10%);
		}
	}

	&.-is-disabled,
	&:disabled {
		@extend %fs-16;
		@include button($grey, $dark-grey, $mid-grey);
		cursor: not-allowed;

		&:hover,
		&:focus {
			color: $dark-grey;
			background-color: $grey;
			border-color: $mid-grey;
		}
	}
}
