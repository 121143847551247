body.mobile-menu-open {
	overflow: hidden;
}
.mobile-app-bar {
	position: fixed;
	bottom: 0;
	width: 100%;
	padding: 0;
	z-index: 99999;

	&__button {
		position: absolute;
		top: -40px;
		left: 50%;
		width: 80px;
		height: 80px;
		padding: 8px !important;
		background: $black;
		border: 2px solid $white;
		border-radius: 50%;
		text-align: center;
		color: $white;
		font-weight: bold;
		text-transform: uppercase;
		cursor: pointer;
		transform: translateX(-50%);

		&:hover,
		&:focus {
			background: $black;
			color: $white;
		}
	}

	&__hamburger {
		display: block;
		position: relative;
		margin: 15px;
		margin-bottom: 8px;
		width: 30px;
		height: 15px;
		transform: rotate(0deg);
		transition: 0.5s ease-in-out;
		cursor: pointer;

		span {
			display: block;
			position: absolute;
			left: 0;
			height: 2px;
			width: 100%;
			background: $white;
			border-radius: 3px;
			opacity: 1;
			transform: rotate(0deg);
			transition: 0.25s ease-in-out;
		}
		span:nth-child(1) {
			top: 0;
		}
		span:nth-child(2),
		span:nth-child(3) {
			top: 6px;
		}
		span:nth-child(4) {
			top: 12px;
		}
		&.is-active span:nth-child(1),
		&.is-active span:nth-child(4) {
			top: 6px;
			width: 0%;
			left: 50%;
		}
		&.is-active span:nth-child(2) {
			transform: rotate(45deg);
		}
		&.is-active span:nth-child(3) {
			transform: rotate(-45deg);
		}
	}

	&__button-text {
		display: block;
		font-size: 10px;
	}

	&__list {
		@include flex-layout(space-around, center);
		height: 72px;
		border-top: 2px solid $white;
	}

	&__item {
		list-style: none;
	}

	&__link {
		@include flex-layout(flex-end, center);
		flex-direction: column;
		height: 47px;
		color: $black;
		&:hover,
		&:focus {
			color: $white;
		}
	}

	&__icon {
		font-size: 24px;
	}

	&__link-text {
		margin-top: 3px;
		font-size: 12px;
		font-weight: bold;
		text-transform: uppercase;
	}
}

.mobile-menu {
	@include transition(height, 500ms, ease);
	height: 0;
	background: $white;

	&.active {
		@include transition(height, 500ms, ease);
		height: 68vh;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}
	.mobileShare {
		@extend %fs-20;
		display: flex;
		padding: 2px 5px 2px 15px;
		background-color: lighten($primary, 10%);
		color: $black;
		align-items: center;
		justify-content: space-between;
		&__icons {
			> a {
				padding: 0 0.5rem;
				font-size: 30px;
				color: $black;
				&:hover,
				&:focus {
					color: $white;
					filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.3));
				}
			}
		}
	}
}
.mobile-menu__nav {
	position: relative;

	li.mobile-menu__navItem:not(.-showInMainMenu) {
		display: none;
	}
	> ul {
		margin: 0;
		padding: 0;
		width: 100%;
		list-style: none;
		text-align: left;

		> li {
			display: block;
			padding: 0;
			border-bottom: 1px solid #d8d8d8;

			> ul {
				display: none;
			}

			> a {
				position: relative;
				display: block;
				padding: 15px 40px 15px 15px;
				border: none;
				color: #000;
				text-decoration: none;
			}
		}

		> li.backLink > a {
			padding-left: 40px;
			background: #d8d8d8;
			text-transform: uppercase;
		}

		> li.backLink > a::after,
		> li.has-menu > a::after {
			position: absolute;
			top: 50%;
			margin-top: -7px;
			font-size: 14px;
			line-height: 1;
			content: '\f054';
			font-family: 'Font Awesome 5 Pro';
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}

		> li.backLink > a::after {
			left: 15px;
			content: '\f053';
		}
	}

	ul > li.has-menu > a::after {
		right: 15px;
	}

	.-in-section > a,
	.-is-selected > a {
		background: #eaeaea;
	}

	> ul > li.mobile-menu__heading a {
		padding-left: 15px;
		padding-right: 40px;
		background-color: #eaeaea;
		color: #000;
		font-weight: bold;
		text-transform: uppercase;
		&::after {
			position: absolute;
			top: 50%;
			right: 15px;
			transform: translateY(-50%);
			line-height: 1;
			content: '\f101';
			font-family: 'Font Awesome 5 Pro';
			speak: none;
			font-style: normal;
			font-weight: bold;
			font-variant: normal;
			font-size: 22px;
			text-transform: none;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}
	}
}
