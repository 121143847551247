.addresses {
	&__map {
		> img {
			border: 2px solid $primary;
			border-radius: 0 44px 0 0;
		}
	}
	&__details {
		line-height: 1;
		padding: 0 20px;
		@include media-max($medium) {
			padding: 0 0 0 15px;
		}
		@include media-max($small) {
			padding: 10px 0 50px;
		}

		p {
			@extend %fs-16;
			margin-bottom: 0.8rem;
		}
		h3 {
			font-weight: bold;
		}
	}
	&__listing {
		margin: 1rem 0;
		&:first-of-type {
			margin-top: 60px;
		}
	}
}
