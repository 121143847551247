.applicationForm {
	margin-top: 2rem;
	margin-left: 0;
	margin-right: 0;
	margin-bottom: 0;

	&__block {
		max-width: 900px;
		margin: 0 auto;
	}
	&__dropdown {
		margin-bottom: 0;
		margin-top: 2rem;
		margin-left: 0;
		margin-right: 0;
		&--label {
			opacity: 0;
			width: 0;
			height: 0;
		}
	}
	&__button {
		margin-top: 2rem;
		display: flex;
	}
	&__file {
		margin-top: 2rem;
		margin-left: 0;
		margin-right: 0;
		@include media($medium) {
			margin-right: 1rem;
		}
	}
	ul.errors li {
		color: $black;
	}
	label[for='email']:before {
		display: none;
	}
	label[for='name']:before {
		display: none;
	}
}
