.pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	flex-wrap: wrap;
	margin: 1.5rem 0;
	padding: 0;
	font-family: $secondary-font-family;
	font-size: 18px;
	font-weight: bold;
	line-height: 1.38;

	@include media($nexus) {
		flex-direction: row;
		justify-content: space-between;
	}
}

.pagination__text {
	flex: 0 0 auto;
	font-weight: normal;
}

.pagination__list {
	display: flex;
	flex: 0 1 auto;
	flex-wrap: wrap;
}

.pagination__button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: calc(2rem + 1rem);
	height: calc(2rem + 1rem);
	min-width: 2rem;
	margin: 0.4rem;
	padding: 1rem 0;
	border: 2px solid $grey;
	background-color: $grey;
	color: $black;
	line-height: 0.8;
	text-align: center;
	font-weight: normal;
	letter-spacing: 0;
	border-radius: 0;
	&:hover,
	&:active,
	&:active {
		background-color: $white;
		border-color: $mid-grey;
		color: $black;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
	}

	&.-isLink {
		border-color: $grey;
		background-color: $grey;
		color: $dark-grey;
		line-height: 0.8;

		&:hover,
		&:active,
		&:focus {
			background-color: #fff;
			border-color: $mid-grey;
			color: $black;
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
		}
	}

	&.-isActive {
		border-color: $primary;
		background-color: $primary;
		color: $black;
		line-height: 0.8;
		cursor: not-allowed;
		&:hover,
		&:active,
		&:focus {
			box-shadow: unset;
		}
	}

	&.-isPrev,
	&.-isNext {
		border-color: $secondary;
		background-color: $secondary;
		color: $white;

		&:hover,
		&:focus {
			box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
			background-color: #fff;
			border-color: $mid-grey;
			color: $black;
		}
	}
}

.pagination__button__icon {
	line-height: 0.9;
}

.searchResults {
	.pagination__button.-isLink {
		border-color: #eaeaea;

		&:hover,
		&:focus {
			border-color: #c4c4c4;
		}
	}
}
