/*  ==========================================================================
	# Variables
	======================================================================= */

// Typography
$base-font-family: 'Century Gothic W01', Arial, sans-serif;
$secondary-font-family: 'Century Gothic W01', Arial, sans-serif;
$heading-font-family: 'Century Gothic W01', Arial, sans-serif;

// Font variations
$base-font-weight: 400;

// Line height
$base-line-height: 1.7;
$reduced-line-height: 1.4;
$card-line-height: 1.4;

// Other Sizes
$base-border-radius: 4px;
$bbr: $base-border-radius;
$button-border-radius: $base-border-radius;
$base-spacing: $base-line-height * 1rem;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Font Colors
$base-font-color: $black;
$action-color: $primary;

// Animations
$base-duration: 250ms;
$base-timing: ease-in-out;

// Borders
$base-border-color: $gray;
$base-border-width: 2px;
$base-border: $base-border-width solid $base-border-color;
$green-border: $base-border-width solid $tertiary;
$box-shadow: 0 4px 4px 2px rgba(0, 0, 0, 0.1);

$base: 0px;
$small: 480px;
$nexus: 600px;
$medium: 768px;
$ipad: 1024px;
$large: 1280px;
$xlarge: 1440px;
$huge: 1681px;
$max: 1920px;

// Padding
$pad-small: 3rem;
$pad-large: 5rem;

$pad-sm: $pad-small;
$pad-lg: $pad-large;

$gutter: 8rem;
