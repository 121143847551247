@import 'photoswipe/dist/photoswipe';
$pswp__assets-path: '../images/';
@import 'photoswipe/src/css/default-skin/default-skin';

/*  ==========================================================================
	# Gallery
	======================================================================= */

.gallery {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	&__item {
		display: block;
		position: relative;
		width: 33%;
		overflow: hidden;
		margin: 0;
		line-height: 1;

		@include media($medium) {
			width: 25%;
		}

		> * {
			position: relative;
			transform: scale(1);
			@include transition();

			&:before,
			&:after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}

			&:before {
				background-color: rgba($secondary, 0);
				@include transition(background-color);
			}
			&:after {
				opacity: 0;
				top: 50%;
				left: 50%;
				width: 44px;
				height: 44px;
				color: $white;
				font-size: 44px;
				transform: translate(-50%, -50%);
				@include transition(opacity);

				content: '\f00e';
				font-family: 'Font Awesome 5 Pro';
				font-weight: 300;
				-moz-osx-font-smoothing: grayscale;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
			}
		}

		&:hover,
		&:focus {
			> * {
				transform: scale(1.1);

				&:before {
					background-color: rgba($secondary, 0.5);
				}
				&:after {
					opacity: 1;
				}
			}
		}

		a {
			display: block;
			margin: 0 !important;
			padding: 0 !important;
			border: none !important;
			background: none !important;
		}
		img {
			display: block;
		}

		@media (hover: none) {
		}
	}
}
