/*  ==========================================================================
	# Colors
	======================================================================= */

// Basic
$white: #fff;
$black: #000;

// Greys
$gray: #ededed;
$grey: $gray;

$focus-grey: #979797;
$focus-gray: $focus-grey;

$visited-grey: #737577;
$visited-gray: $visited-grey;

$active-link-primary: #f7981d;

$mid-gray: #c8c8c8;
$mid-grey: $mid-gray;

$dark-gray: #58585b;
$dark-grey: $dark-gray;

// Project colours
$primary: #f8961d;
$secondary: #58585b;
$tertiary: $primary;

$red: #e84b37;

// UI
$error: $tertiary;

// Define colour map
$colors: (
	white: $white,
	black: $black,
	grey: $grey,
	mid-grey: $mid-grey,
	dark-grey: $dark-grey,
	primary: $primary,
	secondary: $secondary,
	tertiary: $tertiary,
	red: $red,
	hotpink: hotpink,
);
