/*  ==========================================================================
	#Global
	======================================================================= */

html {
	height: 100%;
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

body {
	position: relative;
	min-height: 100%;
	background-color: $white;
	@extend %base;
}

.main {
	position: relative;
	background: $white;

	@include media-max($medium) {
		margin-top: 1rem;
	}
}
.container {
	position: relative;
	width: 100%;
}

.link {
	@extend a;

	&.-white {
		@include link($white);
	}

	&.-black {
		@include link($black);
	}

	&.-featured {
		@include link($tertiary);
	}

	&.-underline {
		text-decoration: underline;
	}

	&.-has-border {
		&:hover,
		&:focus {
			padding: 0.5rem;
			border: 1px solid $white;
			border-radius: $button-border-radius;
		}
	}
}

// inner wrapper for sites with full-width coloured sections
.inner-wrapper {
	@include fp(
		'margin-left',
		15px,
		30px,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'margin-left',
		30px,
		50px,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'margin-left',
		50px,
		50px,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);

	@include fp(
		'margin-right',
		15px,
		30px,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'margin-right',
		30px,
		50px,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'margin-right',
		50px,
		50px,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
}

/* =============================================================================
	# Summary
================================================================================ */
.summary {
	padding-top: 1rem;
	line-height: $reduced-line-height;
}

/* =============================================================================
	# Headings
================================================================================ */

.heading {
	max-width: 900px;
	margin: 0 auto;
	color: $secondary;
	font-family: $heading-font-family;
	font-weight: bold;

	&:first-child {
		margin-top: 0;
	}

	&.-superLarge {
		@extend %fs-64;
	}
	&.-pageHeading {
		@extend %fs-55;
		margin-top: 0;
		margin-bottom: 0;
		font-weight: bold;
		color: $black;
	}
	&.-isCentered {
		text-align: center;
	}

	&.-black {
		color: $black;
	}
	&.-white {
		color: $white;
	}
	&.-primary {
		color: $primary;
	}
	&.-secondary {
		color: $secondary;
	}
}
.-bold {
	font-weight: 700;
}

/* =============================================================================
	# BG
================================================================================ */

.bg {
	background-color: $white;

	&.-primary {
		background-color: $primary;
		color: $white;
	}
	&.-secondary {
		background-color: $secondary;
		color: $white;
	}
	&.-grey {
		background-color: $gray;
	}
	&.-dark-grey {
		background-color: #e1e1e1;
	}
	&.-image {
		@include background-image();
	}
}

/*  ==========================================================================
	#Homepage
	======================================================================= */

/*  ==========================================================================
	#Login/forgot password page
	======================================================================= */

.login,
.forgot-password {
	@include background-image();

	height: 100%;
	min-height: calc(var(--vh, 1vh) * 100);
	padding-bottom: 2rem;
	color: $white;

	&__heading {
		@extend %fs-46;
		margin-bottom: 0.5rem;
	}

	&__text {
		@extend %fs-18;
	}

	&__link {
		font-weight: bold;
	}
}

/*  ==========================================================================
	#Etc
	======================================================================= */

.text-right {
	text-align: right;
}
.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.hidden {
	display: none;
}

/*  ==========================================================================
	## Responsive visibility helpers
	======================================================================= */

.phablet-down {
	display: block;
	@include media($nexus) {
		display: none;
	}
}
.phablet-up {
	display: none;
	@include media($nexus) {
		display: block;
	}
}
.tablet-down {
	display: block;
	@include media($ipad) {
		display: none;
	}
}
.tablet-up {
	display: none;
	@include media($ipad) {
		display: block;
	}
}
.xlarge-down {
	display: block;
	@include media($xlarge) {
		display: none;
	}
}
.large-up {
	display: none;
	@include media($large) {
		display: block;
	}
}
.medium-up {
	display: none;
	@include media($medium) {
		display: block;
	}
}
.ipad-up {
	display: none;
	@include media($ipad) {
		display: block;
	}
}
.small-only {
	display: block;
	@include media($medium) {
		display: none;
	}
}

.-no-y-padding {
	padding-top: 0;
	padding-bottom: 0;
}

.-spacer {
	margin-left: 1rem;
	margin-right: 1rem;
}
