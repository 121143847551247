/*  ==========================================================================
	# Blockquote
	======================================================================= */

blockquote {
	@extend %fs-30;
	position: relative;
	padding-top: 3rem;
	padding-bottom: 3rem;
	margin: 0 0 2rem;
	color: $dark-grey;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: -2px;
		width: 0;
		height: 0;
		border: solid 24px transparent;
		border-top-color: $primary;
		border-left-color: $primary;
		border-radius: 5px;
		@include media-max($medium) {
			border: solid 16px transparent;
			border-top-color: $primary;
			border-left-color: $primary;
		}
	}
	&::after {
		content: "";
		position: absolute;
		right: 2px;
		bottom: 0;
		width: 0;
		height: 0;
		border: solid 24px transparent;
		border-right-color: $primary;
		border-bottom-color: $primary;
		border-radius: 5px;
		@include media-max($medium) {
			border: solid 16px transparent;
			border-right-color: $primary;
			border-bottom-color: $primary;
		}
	}
}
