.passwordPage {
	&__form {
		padding: 15vh 0;
		display: flex;
		flex-direction: column;
	}

	&__loginContainer {
		display: flex;
		max-width: 500px;
	}

	&__input {
		box-sizing: content-box;
		display: inline-flex;
		width: 70%;
		padding: 0.75rem 1.5rem;
		border: 1px solid rgba(0, 0, 0, 0.5);
		outline: none;
	}

	&__button {
		min-width: 30%;
		border: 2px solid #58585b;
	}
}
