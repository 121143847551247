.searchBar {
	transition: all ease-in-out 500ms;
	max-height: 0;
	overflow: hidden;
	z-index: 9999;

	&.panel {
		padding: 0;
	}
	&.-is-active {
		max-height: 100vh;
		@include media($medium) {
			max-height: 20vh;
		}
	}

	&__form {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		padding-left: 2rem;
		padding-right: 2rem;
		padding-bottom: 22px;
		border-radius: 0 16px 0 0;
		background-color: $white;
	}

	&__label {
		position: relative !important;
		font-size: 14px;
		@include media-max($medium) {
			left: 0 !important;
			text-align: center;
		}
	}
	&__input {
		@include media($medium) {
			border-radius: 0 !important;
		}
	}

	&__button {
		width: calc(100% - 1rem);
		@include media-max($medium) {
			width: 100%;
			margin: 0.5rem 0;
			margin-bottom: 0.5rem;
		}
		@include media-max($large) {
			padding: 0.7rem;
		}
		span.icon {
			margin-left: -0.3rem;
		}
	}
}

.searchPage.panel {
	padding-top: 0;
}

.searchResults {
	margin-top: 3rem;

	&__form {
		.searchBar {
			position: static;
			width: 300px;
		}
	}
	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	&__item {
		margin-bottom: 3rem;
		padding-bottom: 3rem;
		border-bottom: solid 1px $grey;

		*:last-child {
			margin-bottom: 0;
		}
	}
	&__link {
		margin-top: 1rem;
		display: block;
	}
	&__summary {
		line-height: $reduced-line-height;
	}
	&__input {
		width: 100%;
		@include media($medium) {
			width: calc(100% - 1rem) !important;
		}
	}
}

@include media-max($medium) {
	.searchBar {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: $grey;
		&__form {
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: calc(100vh - 186px);
			background-color: $grey;
		}
		&__input {
			border: 1px solid lighten($secondary, 40%) !important;
			border-radius: 0 !important;
		}
		&__button {
			span.icon {
				margin-left: 0;
			}
		}
	}
}
